import React from "react";
import { graphql, PageProps } from "gatsby";
import styled from "styled-components";

import { Query } from "@graphql-types";
import SEO from "@shared/seo";
import { PageContext } from "@util/types";
import { Hero } from "@components";
import FooterSections from "@shared/footer/footerSections";
import { Container, PageWidth, Section } from "@util/standard";
import { BlocksContent, Image, Link } from "@global";
import { colors, colorsRGB, MOBILE_BREAKPOINT, SMALLER_MOBILE } from "@util/constants";

interface Props extends PageProps {
  data: Query;
  pageContext: PageContext;
}

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 50px 28px;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px 10px;
  }

  @media only screen and (max-width: ${SMALLER_MOBILE}px) {
    grid-template-columns: 1fr;
    grid-gap: 50px 10px;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  padding-bottom: 100%;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  background-color: ${colorsRGB.main(0.2)};
  margin-bottom: 20px;

  img{
    width: 80%;
    margin: auto;
  }
`;

const LinkStyled = styled.p`
  text-decoration: underline;
`;

export default function CareersTemplate({ data, pageContext }: Props) {
  if (!data.sanityServiceProvidersPage) return null;

  const { pageMeta, providers } = data.sanityServiceProvidersPage;

  if (!pageMeta) return null;

  return (
    <>
      <SEO seoData={pageMeta.seo} slug={pageContext.pagePath} />
      <Hero data={pageMeta.pageHero} />
      {providers &&
        <Section>
          <PageWidth>
            <Container>
              {providers.length > 0 ?
                <GridContainer>
                  {providers.map(provider => {
                    if (!provider) return;

                    return (
                      <Container flexDirection="column" key={provider._key}>
                        <Link {...provider.link}>
                          {provider.image &&
                            <ImageWrapper>
                              <Image isBackground data={provider.image} objectFit="contain" />
                            </ImageWrapper>
                          }
                          <p>{provider.title}</p>
                          <BlocksContent data={provider.blockContent} />
                          <LinkStyled>{provider.link?.linkText}</LinkStyled>
                        </Link>
                      </Container>
                    );
                  })}
                </GridContainer>
                :
                <>We currently have no service providers</>
              }
            </Container>
          </PageWidth>
        </Section>
      }
      <FooterSections pageFooter={pageMeta.pageFooter} />
    </>
  );
}

export const query = graphql`
  query ServiceProvidersQuery {
    sanityServiceProvidersPage {
      pageMeta {
        ...sanityPageMeta
      }
      providers {
        ...sanityCtaWithImage
      }
    }
  }
`;
